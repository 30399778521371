@import "../../dx-styles.scss";
@import "../../themes/generated/variables.additional.scss";

.side-navigation-menu {
  display: flex;
  flex-direction: column;
  min-height: 100%;
  height: 100%;
  width: 250px !important;

  .menu-container {
    min-height: 100%;
    //display: flex;
    flex: 1;

    .dx-treeview {
      // ## Long text positioning
      white-space: nowrap;
      // ##

      // ## Icon width customization
      .dx-treeview-item {
        padding-left: 0;
        padding-right: 0;

        .dx-icon {
          width: $side-panel-min-width !important;
          margin: 0 !important;
        }
      }

      // ##

      // ## Arrow customization
      .dx-treeview-node {
        padding: 0 0 !important;
      }

      .dx-treeview-toggle-item-visibility {
        right: 10px;
        left: auto;
      }

      .dx-rtl .dx-treeview-toggle-item-visibility {
        left: 10px;
        right: auto;
      }

      // ##

      // ## Item levels customization
      .dx-treeview-node {
        &[aria-level='1'] {
          font-weight: bold;
          border-bottom: 1px solid $base-border-color;
        }

        &[aria-level='2'] .dx-treeview-item-content {
          font-weight: normal;
          padding: 0 $side-panel-min-width;
        }
      }

      // ##
    }

    // ## Selected & Focuced items customization
    .dx-treeview {
      .dx-treeview-node-container {
        .dx-treeview-node {
          &.dx-state-selected:not(.dx-state-focused) > .dx-treeview-item {
            background: transparent;
          }

          &.dx-state-selected > .dx-treeview-item * {
            color: $base-accent;
          }

          &:not(.dx-state-focused) > .dx-treeview-item.dx-state-hover {
            background-color: #525260;
          }
        }
      }
    }

    .dx-theme-generic .dx-treeview {
      .dx-treeview-node-container .dx-treeview-node.dx-state-selected.dx-state-focused > .dx-treeview-item * {
        color: inherit;
      }
    }

    // ##
  }
}

.th-sidebar {
  width: 187px;
  list-style: none;
  margin: 2px 0 0;
  padding: 0;

  li {
    border-bottom: 1px solid #babac4;
    border-radius: 1px;
    padding: 13px 17px;
    cursor: pointer;
    background-color: #363640;
    margin-bottom: 2px;

    span {
      font-size: 17px;
      color: white;
    }

    .icon {
      font-size: 20px;
    }

  }

  li:hover {
    background-color: #525260;
  }

  li:active {
    background-color: #9191a1;
  }
}

.dx-drawer-overlap.pre-init-blink-fix {
  @import "../../utils/patches.scss";
  @include menu-pre-init-patch;
}

.float-bottom {
  position: absolute;
  bottom: 0;
}

.fav-list {
  background-color: #f2f2f2;
  border-radius: 4px;
  margin: 10px;
  width: 14.4rem;

  .dx-list-item-content {
    padding: 8px 15px 7px !important;
    font-size: 14px;
    border: 1px !important;
  }

  .dx-list-item:first-of-type {
    margin-top: 0;
  }

  i {
    font-size: 20px !important;
    color: #f05b41;
  }

  .header {
    margin: 11px 11px 0.75rem;
    font-size: 1.20rem;
    color: black;
    padding: 5px;
    border-radius: 4px;
    background-color: #d9d9d9;
    align-content: center;
    box-shadow: 0 .125rem 1.25rem rgba(0, 0, 0, .050) !important;
  }

  span {
    color: black;
  }

  .fav-footer {
    margin: 11px 11px 0.75rem;
  }
}

.float-right {
  float: right;
}

.dx-treeview-node.dx-state-selected > .dx-treeview-item * {
  color: white !important;
}
