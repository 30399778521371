
.container-at {
  background-color: white;
  height: auto;
  max-height: 100%;
  margin: 10px;
  padding: 10px;

  .at-header {
    background-color: #f5f5f3;
    padding: 5px;

    h5 {
      margin: 0px;
      padding: 0px;
    }
  }

  h5 {
    margin: 0;
    padding: 0;
  }

  .at-body {
    padding: 5px;

    .table-tb {
      width: 35%;
      border-collapse: collapse;

      td, th {
        border: 1px solid #f2f2f2;
      }

      .text-right {
        text-align: right;
      }

      .text-left {
        text-align: left;
      }
    }

    .pivot-table {
      width: 35%;
      background-color: #f0f5f5;
      border-collapse: collapse;
      td, th {
        border: 1px solid #fafbfc;
        padding: 2px;
      }
    }

    .risk-loss-table {
      width: 35%;
      background-color: #f0f0f5;
      border-collapse: collapse;

      td, th {
        border: 1px solid #fafbfc;
      }
    }

    .init-trade-btn {
      font-size: 15px;
      text-transform: capitalize;
      color: white;
      font-weight: normal;
      padding: 5px;
    }

    .init-trade-btn:hover {
      font-weight: bold;
    }

    @media only screen and (max-width: 600px) {
      .strategy-list {
        width: auto;
      }

      .table-tb {
        width: 100%;
      }

      .table-tb td, th {
        border: 1px solid #e6e6e6;
        padding: 2px;
      }

      .risk-loss-table {
        width: 100%;
      }

      .pivot-table {
        width: 100% !important;
      }
    }
  }
}

@media only screen and (max-width: 600px) {
  .disconnect-btn {
    float: right;
  }
}

.strategy-list {
  width: 30%;
  list-style: none;
  margin: 2px 0 0;
  padding: 0;

  li {
    border-left: 3.5px solid #808080;
    border-radius: 3px;
    padding: 13px 5px;
    cursor: pointer;
    background-color: #f2f2f2;
    margin-bottom: 2px;

  }

  li:hover {
    background-color: #ebebeb;
  }
}

.strategies {
  h6 {
    margin: 0;
    padding: 0;
  }
}

.kit-login {
  //margin: 20px;

  img {
    height: 100px;
    width: 100px;
  }
}

.cust-btn {
  border: 1px solid black;
  background-color: white;
  color: black;
  padding: 3px 5px;
  font-size: 13px;
  cursor: pointer;
  border-radius: 3px;
}

.btn-danger {
  border-color: red;
  color: red;
}

.btn-danger:hover {
  background-color: #ffe6e6;
}

.btn-danger:active {
  background-color: red;
  color: white;
}

.btn-info {
  border-color: skyblue;
  color: skyblue;
}

.btn-info:hover {
  background-color: #ccf5ff;
}

.btn-info:active {
  background-color: skyblue;
  color: black;
}

.btn-primary {
  border-color: cornflowerblue;
  background-color: ghostwhite;
  color: blue;
}

.btn-primary-purple {
  border-color: #ffebe6;
  background-color: rebeccapurple;
  color: white;
  padding: 5px;
}

.btn-primary-orange {
  border-color: #ffebe6;
  background-color: #ff5722;
  color: white;
  padding: 5px;
}

.btn-primary-orange:hover {
  background-color: #ff5511 !important;
}

.btn-primary-green {
  border-color: darkgreen;
  background-color: green;
  color: white;
  padding: 5px;
}

.btn-primary-green:hover {
  background-color: darkgreen;
}

.btn-primary-blue {
  border-color: #ffebe6;
  background-color: #223fff;
  color: white;
}

.btn-primary-disabled {
  cursor: not-allowed;
  background-color: #eeeeee !important;
}

//.btn-primary:hover {
//  background-color: #ffebe6;
//}

.btn-success {
  border-color: green;
  color: #28a745;
}

.btn-success:hover {
  background-color: #d6f5dd;
}

.btn-success:active {
  background-color: #28a745;
  color: white;
}

.btn-default {
  border-color: lightgray;
  background-color: white;
  color: #222222;
}

/*.btn-default:hover {
  background-color: white;
}*/

.btn-default:active {
  background-color: #e7e7e7;
  color: black;
}

.btn-outline-0 {
  border: none;
}

.font-lighter {
  font-weight: lighter;
  letter-spacing: 0.5px;
}

.info-field {
  background-color: #eee39e;
  padding: 4px;
  font-size: 13px;

  button {
    font-size: 12px;
    color: #5780cf;
  }
}

.info-field-orange {
  background-color: #ecc8b9;
  padding: 4px;
  font-size: 13px;

  button {
    font-size: 12px;
    color: #5780cf;
  }
}

.info-field-green {
  background-color: #cdf1cd;
  padding: 4px;
  font-size: 13px;

  button {
    font-size: 12px;
    color: #5780cf;
  }
}

.info-field-purple {
  background-color: #cebee8;
  padding: 4px;
  font-size: 13px;
}

.info-field-blue {
  background-color: #d4f8f8;
  padding: 4px;
  font-size: 13px;

  button {
    font-size: 12px;
    color: #5780cf;
  }
}

.cust-bg-light {
  padding: 5px 0;
  border: 1px solid lightgrey;
  background-color: rgba(222, 222, 203, 0.35);
}

.p-5 {
  padding: 5px
}

.p-10 {
  padding: 10px
}

.row {
  display: -ms-flexbox;
  display: flex;
  flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  width: 100%;
}

.col {
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%;
  width: 100%;
  position: relative;
}

.header.header-text.header-bg-default {
  font-size: 17px;
  background-color: #e7e7e7;
}

.m-10 {
  margin: 10px;
}

.m-5 {
  margin: 5px;
}

.p-5 {
  padding: 5px;
}

.mb-10 {
  margin-bottom: 10px;
}

.ml-10 {
  margin-left: 10px;
}

.mr-10 {
  margin-right: 10px;
}

.mt-10 {
  margin-top: 10px;
}

.content-center {
  display: flex;
  justify-content: center;
}

.label-green {
  border:1px solid green;
  background-color: green;
  color: white;
  padding: 4px 7px;
  border-radius: 7px;
  margin: 0;
  font-weight: normal;
}

.label-orangered {
  border:1px solid orange;
  background-color: orangered;
  color: white;
  padding: 3px 4px;
  border-radius: 7px;
  margin: 0;
  font-weight: normal;
}
.label-blue {
  border:1px solid blue;
  background-color: rgba(0, 0, 255, 0.56);
  color: white;
  padding: 3px 4px;
  border-radius: 7px;
  margin: 0;
  font-weight: normal;
}

.label-lightblue {
  border:1px solid lightblue;
  background-color: lightskyblue;
  color: black;
  padding: 3px 4px;
  border-radius: 7px;
  margin: 0;
  font-weight: normal;
}

.label-red {
  border:1px solid white;
  background-color: rgba(255, 0, 0, 0.63);
  color: white;
  padding: 3px 4px;
  border-radius: 7px;
  margin: 0;
  font-weight: normal;
}
.label-purple {
  border:1px solid white;
  background-color: rgba(162, 119, 227, 0.48);
  color: black;
  padding: 3px 4px;
  border-radius: 7px;
  margin: 0;
  font-weight: normal;
}

.label-yellow {
  border:1px solid green;
  background-color: antiquewhite;
  color: green;
  padding: 3px 4px;
  border-radius: 7px;
  margin: 0;
  font-weight: normal;
}


.label-pink {
  border:1px solid white;
  background-color: rgba(214, 157, 234, 0.66);
  color: black;
  padding: 3px 4px;
  border-radius: 7px;
  margin: 0;
  font-weight: normal;
}
