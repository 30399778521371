.logos-container {
  margin: 20px 0 40px 0;
  text-align: center;

  svg {
    display: inline-block;
  }
}

.devextreme-logo {
  width: 200px;
  height: 34px;
  margin-bottom: 19px;
}

.react-logo {
  width: 184px;
  height: 68px;
}

.plus {
  margin: 23px 10px;
  width: 22px;
  height: 22px;
}

.screen-x-small .logos-container {
  svg {
    width: 100%;
    display: block;

    &.plus {
      margin: 0;
    }
  }
}

.dashboard-widget-shadow {
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2), 0 4px 10px 0 rgba(0, 0, 0, 0.19);
}

.dashboard-title {
  font-size: 18px;
  margin: 5px 20px 0;
  font-weight: lighter;
}

.dashboard-body {
  margin-left: 20px;
  margin-right: 5px;
}

.dx-card-p {
  padding: 10px;
}

.th-dashboard {
  background-color: white;

  .text-day {
    color: #9e9e9e;
    font-weight: lighter;
    float: right;
  }

  .row {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    width: 100%;
  }

  .col {
    height: 470px;
    margin: 10px;
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
    position: relative;
    width: 100%;
  }

  .symbol-link {
    color: #0817ee;
    text-decoration: none;
  }


  .long-buildup {
    background-color: white;

    .header {
      margin: 0;
      padding-left: 10px;
      padding-right: 5px;
      background-color: #e6ffe6;
      border-bottom: 2px solid #eaebee;
      text-align: left;
      font-weight: 400;
      font-size: 19px;
    }

    ul {
      font-size: 13px;
      padding: 4px;
      margin: 4px;
      height: 420px;
      overflow-y: scroll;
    }

    table {
      width: 100%;
      padding: 5px;
      margin-bottom: 4px;
      border-radius: 4px;
      border: 1px solid #e6e6e6;
      table-layout: auto;
    }

    table tbody tr {
      background-color: #ccffcc;
    }

    table thead {
      color: black;
      //float: left;
      border-radius: 4px;

      //th {
      //  display: inline-flex;
      //}
    }

    table:hover {
      box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    }
  }

  .short-buildup {
    background-color: white;

    .header {
      margin: 0;
      padding-left: 10px;
      padding-right: 5px;
      background-color: #ffe6e6;
      border-bottom: 1px solid #eaebee;
      text-align: left;
      font-weight: 400;
      font-size: 19px;
    }

    ul {
      font-size: 13px;
      padding: 4px;
      margin: 4px;
      height: 420px;
      overflow-y: scroll;
    }

    table {
      width: 100%;
      padding: 5px;
      margin-bottom: 4px;
      border-radius: 4px;
      border: 1px solid #e6e6e6;
      table-layout: auto;
    }

    table tbody tr {
      background-color: #ffcccc;
    }

    table thead {
      color: black;
      //float: left;
      border-radius: 4px;

      th {
        //display: inline-flex;
      }
    }

    table:hover {
      box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    }
  }

  .oi-spurts {
    background-color: white;

    .header {
      margin: 0;
      padding-left: 10px;
      padding-right: 5px;
      background-color: #e6ecff;
      border-bottom: 1px solid #eaebee;
      text-align: left;
      font-weight: 400;
      font-size: 19px;
    }

    ul {
      font-size: 13px;
      padding: 4px;
      margin: 4px;
      height: 420px;
      overflow-y: scroll;
    }

    table {
      width: 100%;
      padding: 5px;
      margin-bottom: 4px;
      border-radius: 4px;
      border: 1px solid #e6e6e6;
    }

    table tbody tr {
      background-color: #d1e1fb;
    }

    table thead {
      color: black;
      //float: left;
      border-radius: 4px;

      th {
        //display: inline-flex;
      }
    }

    table:hover {
      box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    }
  }

  .dull-oi {
    background-color: white;

    .header {
      margin: 0;
      padding-left: 10px;
      padding-right: 5px;
      background-color: #f1f3f4;
      border-bottom: 1px solid #eaebee;
      text-align: left;
      font-weight: 400;
      font-size: 19px;
    }

    ul {
      font-size: 13px;
      padding: 4px;
      margin: 4px;
      height: 420px;
      overflow-y: scroll;
    }

    table {
      width: 100%;
      padding: 5px;
      margin-bottom: 4px;
      border-radius: 4px;
      border: 1px solid #e6e6e6;
    }

    table tbody tr {
      background-color: #d9dbdc;
    }

    table thead {
      color: black;
      //float: left;
      border-radius: 4px;

      th {
        //display: inline-flex;
      }
    }

    table:hover {
      box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    }
  }
}

.link-text {
  color: #0817ee !important;
  text-decoration: underline;
  cursor: pointer;
}