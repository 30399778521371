@import "../../themes/generated/variables.base.scss";

.login-form {
  .link {
    text-align: center;
    font-size: 16px;
    font-style: normal;

    a {
      text-decoration: none;
    }
  }

  .form-text {
    margin: 10px 0;
    color: #fff;
  }
}

.login-header {
  font-size: 17px;
  font-weight: lighter;
}

.login-body {
  margin-top: 1vw;
  margin-bottom: 1vw;
  display: flex;
  justify-content: center;
  align-content: center;
}

.login-footer {
  bottom: 0;
  text-align: center;
}
