@import "../../themes/generated/variables.base.scss";
@import "../../dx-styles.scss";

.header-component {
  flex: 0 0 auto;
  z-index: 1;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);

  .dx-toolbar .dx-toolbar-item.menu-button>.dx-toolbar-item-content .dx-icon {
    color: $base-accent;
  }
}

.dx-toolbar.header-toolbar .dx-toolbar-items-container .dx-toolbar-after {
  padding: 0 40px;

  .screen-x-small & {
    padding: 0 20px;
  }
}

.dx-toolbar .dx-toolbar-item.dx-toolbar-button.menu-button {
  width: $side-panel-min-width;
  text-align: center;
  padding: 0;
}

.header-title .dx-item-content {
  padding: 0;
  margin: 0;
}

.header-current-time .dx-item-content {
  font-size: 25px;
  font-weight: bold;
  color: darkblue;
}

.dx-theme-generic {
  .dx-toolbar {
    padding: 10px 0;
  }

  .user-button>.dx-button-content {
    padding: 3px;
  }
}

.guest-user-text {
  font-weight: lighter;
  margin-right: 5px;
  font-size: 17px;
}

.float-right {
  float: right;
}

.dropbtn {
  background-color:  #e6ffe6;
}

.dropdown {
  position: relative;
  display: inline-block;
}

.dropdown-content {
  display: none;
  position: absolute;
  background-color: #ffffff;
  border-radius: 5px;
  border: 1px solid #d9d9d9;
  min-width: 230px;
  box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2);
  z-index: 1;
  list-style-type: none;
  text-overflow: ellipsis;


  ul {
    padding: 0;
    height: 350px;
    overflow-y: scroll;
    margin-bottom: 5px;
  }

  ul li {
    padding: 2px;
    border: 1px solid #d9d9d9;
    background-color: #f2f2f2;
    border-radius: 3px;
    margin: 4px;
    cursor: pointer;

    .txt-symbol {
      font-weight: bold;
      float: left;
    }

    .txt-date {
      font-weight: lighter;
      color: #a6a6a6;
      float: left;
    }
  }

  .footer {
    border-top: 1px solid  #bfbfbf;
    display: flex;
    padding: 5px;
    justify-content: center;
  }
}

.dropdown-content .options {
  color: black;
  padding: 2px 5px;
  text-decoration: none;
  display: block;

  button {
    display: none;
    border: 1px solid red;
    color: red;
    border-radius: 3px;
    padding: 5px;
    background-color: white;
  }

  button:hover{
    background-color: #ed60601a;
    box-shadow: 0 5px 10px rgb(255, 204, 204);
  }

  button:active{
    background-color: red;
    box-shadow: white;
    color: white;
  }

  .grid-container {
    display: grid;
    grid-row-gap: 50px;
    grid-template-columns: auto auto auto;
  }

  .grid-item {
    padding: 5px;
    text-align: center;
  }

  .grid-item:first-child:active {
      background-color: #ffebe6;
      color: black;
  }
}

.dropdown-content .options:hover {
  background-color: white;

  button {
    display: block;
  }
}

.dropdown:hover .dropdown-content {display: block;}

.dropdown:hover .dropbtn {background-color:  #e6ffe6;}

::-webkit-scrollbar {
  width: 6px;
}

/* Track */
::-webkit-scrollbar-track {
  background: white;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #9e9e9e;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}
