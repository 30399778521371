
.container-studies {
  background-color: #555;
  max-height: 100%;
  margin: 0;
  padding: 0;

  .study-header {
    background-color: #333;
    padding: 5px;
    color: white;
    width: 100%;

    .text-right {
      text-align: right;
    }
  }

  .study-body {
    padding: 5px;

    .study-title {
      font-size: 14px;
      text-align: left;
      padding: 6px;
      border-top-left-radius: 8px;
      border-top-right-radius: 8px;
    }

    .main-section-title {
      font-size: 18px;
    }

    .study-momentum-title {
      color: floralwhite;
      background-color: darkmagenta;
    }

    .study-intraday-boost-title {
      color: floralwhite;
      background-color: blue;
    }

    .study-high-power-stocks-title {
      color: floralwhite;
      background-color: dodgerblue;
    }

    .study-pre-market-title {
      color: whitesmoke;
      background-color: hotpink;
    }

    .study-sectors-view-title {
      color: floralwhite;
      background-color: mediumpurple;
    }
    .study-stock-info-title {
      color: black;
      background-color: gold;
    }
    .study-stock-info2-title {
      color: black;
      background-color: palegoldenrod;
    }

    .study-stock-info {
      color: #444;
      background-color: floralwhite;
      height: 500px;
      overflow-y: scroll;
    }

    .study-section {
      padding:5px;
      background-color: floralwhite;
    }

    .study-stock-trade {
      color: #444;
      background-color: floralwhite;
      height:500px;
      overflow-y: scroll;
      padding:10px;
    }

    .study-top-sector-stocks-title {
      color: floralwhite;
      background-color: mediumpurple;
    }

    .study-watchlist-title {
      color: #444;
      background-color: white;
    }

    .study-swing-upside-title {
      color: black;
      background-color: limegreen;
    }
    .study-swing-downside-title {
      color: whitesmoke;
      background-color: indianred;
    }

    .r-factor {
      color: white !important;
      background-color: darkorange !important;
      border-radius: 50px;
      font-weight: bold;
      margin: 2px;
    }

    .volume-change {
      margin: 2px;
      color: #222 !important;
      background-color: navajowhite !important;
      border-radius: 50px;
    }

    .change-positive-icon {
      color: forestgreen !important;
      font-size:20px;
    }

    .change-positive {
      color: white !important;
      background-color: forestgreen !important;
    }

    .change-negative {
      color: white !important;
      background-color: indianred !important;
    }

    .study-table {
      width: 100%;
      border-collapse: collapse;

      td, th {
        border: 1px solid antiquewhite;
      }

      th {
        background-color: #ddd;
        color: #222222;
        font-weight: normal;
      }

      tr {
        background-color: white;
      }

      .text-right {
        text-align: right;
      }

      .text-center {
        text-align: center;
      }

      .text-left {
        text-align: left;
      }

    }

    @media only screen and (max-width: 600px) {
      .strategy-list {
        width: auto;
      }

      .table-tb {
        width: 100%;
      }

      .table-tb td, th {
        border: 1px solid #e6e6e6;
        padding: 2px;
      }

      .risk-loss-table {
        width: 100%;
      }

      .pivot-table {
        width: 100% !important;
      }
    }
  }
}

@media only screen and (max-width: 600px) {
  .disconnect-btn {
    float: right;
  }
}

.highlighted-stock {
  background-color: lawngreen !important;
  color: black;
}

.highlighted-sector {
  background-color: yellow !important;
  color: black;
}

.clickable-stock {
  cursor: pointer;
  border-radius: 3px;
  font-weight: normal;
}

.skipped-stock {
  text-decoration: line-through !important;
  background-color: #eeeeee !important;
}

.clickable-stock {
  td {
    cursor: pointer;
  }
}

.clickable-stock:hover {
  background-color: floralwhite;
}

.highlighted-stock:hover {
  background-color: lawngreen;
}

.swing-today {
  background-color: lightyellow !important;
}

.dull-text {
  color:grey;
  font-size: smaller;
}

.row {
  display: -ms-flexbox;
  display: flex;
  flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  width: 100%;
}

.col {
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%;
  width: 100%;
  position: relative;
  margin: 4px;
}
