.admin-page {
  margin: 10px;
  background-color: white;

  .upload {
    height: auto;
    border: 1px solid #96bcf8ad;
    margin-top: 10px;
    margin-bottom: 10px;
    background-color: #f2f5f867;
    padding: 8px;
    border-radius: 3px;
  }

  .file-content {
    display: table;
    table-layout: fixed;
    height: 100%;
    width: 100%;
    padding: 15px;
  }

  .ohlc-container {

    .dx-popup-content {
      padding: 20px !important;
    }

    .ohlc-body {
      .text-right {
        text-align: right;
      }

      .ohlc-table {
        width: 100%;
        border-collapse: collapse;

        thead {
          background-color: #f2f2f2;

          th {
            border: 1px solid white;
            padding: 5px;
          }
        }

        td, th {
          border: 1px solid #efefef;
        }

      }

      .input-form {
        width: 100%;

        input {
          width: 100%;
        }
      }
    }
  }

  .column {
    float: left;
  }

  .pivot-table {
    border-collapse: collapse;
    width: auto;
    text-align: right;
    background-color: #eeefff;
    border: 1px solid grey;
    padding:10px;

    tr, th {
      padding: 5px;
    }

    tr, td {
      padding: 5px;
      border: 1px solid white;
    }
  }

  .pt-div{
    display: flex;
  }

  /* Clear floats after the columns */
  .row:after {
    content: "";
    display: table;
  }

  .holiday-container {
    height: auto;
    border: 1px solid #96bcf8ad;
    margin-top: 10px;
    margin-bottom: 10px;
    background-color: #f2f5f867;
    padding: 8px;
    border-radius: 3px;

    .holiday-header {
      padding: 4px !important;
      background-color: #ffebe6;
    }

    .holiday-body {
      margin-bottom: 5px;

      .holidays-table {
        width: 50%;

        thead {
          background-color: #e6e6e6;

          th {
            padding: 3px;
          }
        }

        tbody {
          tr {
            padding: 3px;
          }

          tr:hover {
            background-color: #f2f2f2;
          }
        }

        td {
          text-align: center;
        }

        .delete-holiday-btn {
          color: red;
          padding: 4px;
        }

        .delete-holiday-btn:hover {
          border-radius: 3px;
          border-color: red;
          background-color: #ffe6e6;
        }

        .delete-holiday-btn:active {
          border-color: red;
          background-color: red;
          color: white;
        }
      }
    }
  }
}
