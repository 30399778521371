@import "../../themes/generated/variables.base.scss";

.create-account-form {
  .policy-info {
    margin: 10px 0;
    color: #fff;
    font-size: 14px;
    font-style: normal;

    a {
      color: #fff;
    }
  }

  .login-link {
    color: $base-accent;
    font-size: 16px;
    text-align: center;
  }
}
