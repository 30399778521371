
.preference {
  margin: 15px;
  background-color: white;
  padding: 5px;

  .react-tabs__tab-list {
    margin: 0 0 0 !important;
  }


  .row {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    width: 100%;
  }

  .col {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
    position: relative;
    width: 100%;
  }
}

@media only screen and (max-width: 600px) {
  .react-tabs__tab-list li {
    font-size: 10px !important;
  }
}

/*-- Tab related style --*/
.tab-content {
  border: 1px solid #96bcf8ad;
  margin-top: 10px;
  margin-bottom: 10px;
  background-color: white;
  padding: 8px;
  border-radius: 3px;
  height: auto;
}

.react-tabs__tab--selected {
  border-color: #e8f0fe !important;
  color: #ff5722 !important;
  font-weight: bold !important;
  background-color: #ffebe6 !important;
  border-bottom: 3px solid #ff5722 !important;
  border-radius: 2px 2px 0 0 !important;
}

.label {
  font-weight: bold;
  font-size: 12px;
}
